import 'src/packages/navbar/SearchInputNavbar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+VYz2+bMBS+969AmiatB0ckhP6gpzZSpV6madtlp8oxDng1NjOmaTbtf59tcAIBEpO2p10aIOH73vv8/L3nTr5hKFD6wPJSfobPSygeF5xJSBgWj49slazna9/7c+Z5MSlyCjeRt6L45UY9WJNYppE39f2P+nYJ0VMieMligDjlIvKeofgEQIFxrP5kkEmCqq9ADnMsQIxXsKTy/Obs79mkG8cXmOA7BdqJZ9ofD6QkYYBInBWRhzCTWOjHOYxjwpLI8z1/EgqcDdCZ6zv+Yllm/SyH00SQ4jrHRMANUOKc7wUxnVzqKEw4l1U8CpWLWCkiYExKFb0/CbZfSQFZQSThLOqQe4HvZ4VrQhFEkjxjk9e4NGZ1GgdjGY7kK874M14QgSh+QJzZiAITiq2jyXRmc04xSVLZfDac4QIyhKmFnPevWs5t2HBZcFpKfOPK3Fy8yWzWWpYVF1lUXVIo8Y9PIPQ/GqVQKQqtas6JLUSwxssnIoGEOUgVC9VMVn2DkUOhynZfaJ5DROTm+FrvKdFc7hrDpDCE77uAK1mlzvrxlsWCk9iyhYZG8jzywsoPhBUydFzBLfSiVO4gCeMW/GIHPg/b4HMn7O8CQ5kpbS3i5X9fJl1J3qNedixDhXM1WDgjV3a4fK4Hy+cIhQWALZ+q+91KtSVQkN/Y4ngeVV0K7EojaNj7i/6pqY7a6tUj07TyXHHrVCKPcYZ3zUBp3+43ux/wUmqq3QMHC7+uLXxMeSF1tf3uwzy8ur+/d9IritRqIJxyqhIx4jlEGJoIndBtEoX5pRokEBdQV2d7W1fynIKITHGBZSnl24EKXKhxp3gn1JNFGNqZS4PTcLXwIjCDyySsP+p7N/TOtkT7+PPgqoVv7w/h36r9bwFjA6i9XO2+tp0LrMJQ5nYUakgM3BFDhzZtxRscnVXaFB1FVvskVXOxNMF8Xl048XQMPnm9PMftPO3Vaf/DTSkXbycDku2oXETTxww9mHaOGj/754RXtu1GuMHQEaBuhs7O7pjX0Lo9VT3y1LPGCKI3GzMOcHbKhHbScxmXRjGcfroKnU5Xh4K8FYKvmyerrH2y6o6tBzbDgvIC35ke1dkPbNTR+5XbpH9HnFyjB/N6s6pssFhwXhmvnhdXMCNUIROWYkFkc9rbTn89A1/flNiYP/v+0SI3OVfVlacbQOES05kaFJKSQgG2L55vYdZ1XZwAVL163hl/x0E1Xq2gsFQFAwqttjbKkWittw3gW1v4e7h1s3SG7DN3mEMOdbpejo6Z/Ro5iPwD2Dk1HUYUAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var BackArrowIcon = 'SearchInputNavbar_BackArrowIcon__nfgw4wm';
export var BackIconContainer = _7a468({defaultClassName:'SearchInputNavbar_BackIconContainer__nfgw4wj',variantClassNames:{platform:{Android:'SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wk',Cupertino:'SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4wl'}},defaultVariants:{},compoundVariants:[]});
export var CloseButton = _7a468({defaultClassName:'SearchInputNavbar_CloseButton__nfgw4wo',variantClassNames:{platform:{Android:'SearchInputNavbar_CloseButton_platform_Android__nfgw4wp',Cupertino:'SearchInputNavbar_CloseButton_platform_Cupertino__nfgw4wq'}},defaultVariants:{},compoundVariants:[]});
export var CloseButtonContainer = 'SearchInputNavbar_CloseButtonContainer__nfgw4wn';
export var Container = 'SearchInputNavbar_Container__nfgw4w0';
export var Input = _7a468({defaultClassName:'SearchInputNavbar_Input__nfgw4wa',variantClassNames:{platform:{Android:'SearchInputNavbar_Input_platform_Android__nfgw4wb',Cupertino:'SearchInputNavbar_Input_platform_Cupertino__nfgw4wc'}},defaultVariants:{},compoundVariants:[]});
export var InputArea = _7a468({defaultClassName:'SearchInputNavbar_InputArea__nfgw4wd',variantClassNames:{platform:{Android:'SearchInputNavbar_InputArea_platform_Android__nfgw4we',Cupertino:'SearchInputNavbar_InputArea_platform_Cupertino__nfgw4wf'}},defaultVariants:{},compoundVariants:[]});
export var InputAreaTreatment = _7a468({defaultClassName:'SearchInputNavbar_InputAreaTreatment__nfgw4wg',variantClassNames:{platform:{Android:'SearchInputNavbar_InputAreaTreatment_platform_Android__nfgw4wh',Cupertino:'SearchInputNavbar_InputAreaTreatment_platform_Cupertino__nfgw4wi'}},defaultVariants:{},compoundVariants:[]});
export var InputBox = 'SearchInputNavbar_InputBox__nfgw4w2';
export var InputCancel = _7a468({defaultClassName:'SearchInputNavbar_InputCancel__nfgw4w4',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancel_platform_Android__nfgw4w5',Cupertino:'SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w6'}},defaultVariants:{},compoundVariants:[]});
export var InputCancelTreatment = _7a468({defaultClassName:'SearchInputNavbar_InputCancelTreatment__nfgw4w7',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancelTreatment_platform_Android__nfgw4w8',Cupertino:'SearchInputNavbar_InputCancelTreatment_platform_Cupertino__nfgw4w9'}},defaultVariants:{},compoundVariants:[]});
export var PageBackContainer = 'SearchInputNavbar_PageBackContainer__nfgw4w1';
export var RemoveCircleIcon = 'SearchInputNavbar_RemoveCircleIcon__nfgw4w3';