import type { GlobalState } from './GlobalStore'
import type { StateCreator } from 'zustand'
import { placeMapSlice } from '../../place/stores/placeMapSlice'

export type GlobalStoreActionTypes = {
  // 동네업체 지도
  placeMapChangeVisibility: (isVisible: boolean) => void
}

export const globalStoreActions: StateCreator<
  GlobalState,
  [],
  [],
  GlobalStoreActionTypes
> = (set, get, api) => ({
  placeMapChangeVisibility: (isVisible: boolean) => {
    placeMapSlice(set, get, api).placeMap.changeMapVisibility(isVisible)
  },
})
