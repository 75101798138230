import { createStore } from 'zustand'
import { devtools } from 'zustand/middleware'
import type { experimentStateType } from '../../experiment/stores/ExperimentStore'
import type { GlobalStoreActionTypes } from './GlobalStoreActions'
import { globalStoreActions } from './GlobalStoreActions'
import { placeMapSlice } from '../../place/stores/placeMapSlice'
import { STAGE } from '../constants/common'

type SliceTypes = ReturnType<typeof placeMapSlice>

export type GlobalState = {
  experimentState: experimentStateType
  actions: GlobalStoreActionTypes
} & SliceTypes

export type GlobalStore = ReturnType<typeof createGlobalStore>

export interface GlobalStoreProps {
  initGlobalState: Partial<GlobalState>
}

export const createGlobalStore = ({ initGlobalState }: GlobalStoreProps) => {
  const DEFAULT_PROPS = {
    experimentState: {
      experimentSegments: [],
      headerSegmentKey: '',
      headerSegmentValue: '',
    },
  }

  return createStore<GlobalState>()(
    devtools(
      (...args) => ({
        ...DEFAULT_PROPS,
        ...initGlobalState,
        // TODO: Slices of state
        ...placeMapSlice(...args),
        // Actions
        actions: globalStoreActions(...args),
      }),
      {
        enabled: STAGE === 'development',
        name: 'GlobalStore',
      }
    )
  )
}
