import { createContext, useContext, useEffect, useRef } from 'react'
import type { StackflowReactPlugin } from '@stackflow/react'

type Listener = () => void

let installed = false

const isIOSWebView =
  typeof window !== 'undefined' && window.webkit !== undefined

const detector: Detector | null = installDetector()

function nextTick(): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, 16))
}

type Detector = {
  uninstall: () => void
  addListener: (listener: Listener) => () => void
}

function installDetector(): Detector {
  if (installed) {
    throw new Error('Scroll to top detection is already installed')
  }

  installed = true

  let touched = false
  const listeners: Set<Listener> = new Set()

  function addListener(listener: Listener) {
    listeners.add(listener)

    function disposeListener() {
      listeners.delete(listener)
    }

    return disposeListener
  }

  function onScroll() {
    // iOS scroll to top 기능을 이용하지 않는 경우 (ex) touch 로 스크롤된 경우) 에는 scroll 이벤트 무시
    if (touched || window.scrollY !== 0) {
      return
    }

    const isKeyboardVisible = window.visualViewport
      ? window.innerHeight - window.visualViewport.height > 0
      : false

    if (isKeyboardVisible) {
      window.scroll({ top: 1 })
      return
    }

    listeners.forEach((listener) => listener())

    // install시점에서 window.outerHeight가 0으로 잡히는 경우가 있어서 onScroll시 마다 갱신.
    window.document.body.style.height = window.outerHeight + 1 + 'px'
    window.scroll({ top: 1 })
  }

  function onTouchStart() {
    if (!isIOSWebView) {
      return
    }

    touched = true
    window.document.body.style.overflow = 'hidden'
  }

  function onTouchEnd() {
    if (!isIOSWebView) {
      return
    }

    nextTick().then(() => {
      touched = false
      window.document.body.style.overflow = ''
    })
  }

  function onResize() {
    window.document.body.style.height = window.outerHeight + 1 + 'px'
  }

  function install() {
    window.document.body.style.height = window.outerHeight + 1 + 'px'
    window.scroll({ top: 1 })

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onScroll)
    window.document.body.addEventListener('touchstart', onTouchStart)
    window.document.body.addEventListener('touchend', onTouchEnd)
    window.document.body.addEventListener('touchcancel', onTouchEnd)
  }

  function uninstall() {
    window.document.body.style.height = ''
    window.scroll({ top: 0 })

    window.removeEventListener('resize', onResize)
    window.removeEventListener('scroll', onScroll)
    window.document.body.removeEventListener('touchstart', onTouchStart)
    window.document.body.removeEventListener('touchend', onTouchEnd)
    window.document.body.removeEventListener('touchcancel', onTouchEnd)

    listeners.clear()

    installed = false
  }

  if (isIOSWebView) {
    if (document.readyState === 'complete') {
      install()
    } else {
      window.addEventListener('load', install)
    }
  }

  return {
    uninstall,
    addListener,
  }
}

const DetectorContext = createContext<Detector>(null as any)

export const iOSScrollToTopPlugin: () => StackflowReactPlugin = () => () => ({
  key: 'stackflow-plugin-scroll-to-top',
  wrapStack({ stack }) {
    if (detector) {
      return (
        <DetectorContext.Provider value={detector}>
          {stack.render()}
        </DetectorContext.Provider>
      )
    } else {
      return <>{stack.render()}</>
    }
  },
})

export function useIOSScrollToTopEffect(
  cb: () => void,
  deps?: any[],
  options?: {
    isActive: boolean
  }
) {
  const detector = useContext(DetectorContext)
  const disposeRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (!isIOSWebView) {
      return
    }

    if (!options?.isActive) {
      disposeRef.current?.()
    } else {
      disposeRef.current = detector?.addListener(cb)
    }

    return () => {
      disposeRef.current?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.isActive, detector, ...(deps ?? [])])
}
